import { getCookies, setCookies } from './helper';

const win = window;

export default class CookieConsent {
  constructor(rootNode) {
    this.$refs = {
      rootNode,
      bodyElement: document.body,
      toggleItems: [
        ...rootNode.querySelectorAll('.script-list-item.is-toggable'),
      ],
      buttons: [...rootNode.querySelectorAll('.script-actions-btn')],
      checkboxes: [...rootNode.querySelectorAll('.CookiesSwitch input')],
    };

    this.cookies = getCookies();
    this.tagManagerId = rootNode.dataset.tagmanagerid;
    this.tagManagerSource = rootNode.dataset.tagmanagersource;
    this.isModal = rootNode.dataset.isModal || false;

    // if no backend-config provided, stop
    if (!this.tagManagerId) return;
    if (!this.tagManagerSource) return;

    if (!this.cookies || !this.isModal) this.init();
    if (this.cookies && CookieConsent.hasAnyTrueValue()) {
      CookieConsent.enableTagManager(this.tagManagerId, this.tagManagerSource);
      CookieConsent.fireCookieEvents();
    }
  }

  init() {
    if (this.isModal) this.openModal();
    else this.setCheckboxValues();

    this.setupInfoTogglers();
    this.setupBtnActions();
  }

  openModal() {
    this.$refs.bodyElement.classList.add('modal-active');
    this.$refs.rootNode.setAttribute('aria-hidden', false);
    this.$refs.rootNode.classList.add('show'); // show modal
  }

  closeModal() {
    this.$refs.bodyElement.classList.remove('modal-active');
    this.$refs.rootNode.setAttribute('aria-hidden', true);
    this.$refs.rootNode.classList.remove('show'); // show modal
  }

  setupInfoTogglers() {
    this.$refs.toggleItems.forEach((item) => {
      const btn = item.querySelector('.script-item-btn');

      btn.addEventListener('click', () => {
        item.classList.toggle('is-open');
      });
    });
  }

  setupBtnActions() {
    this.$refs.buttons.forEach((button) => {
      button.addEventListener('click', () => {
        const action = button.dataset.action;

        // get all script-ids and save cookie-values
        if (action === 'accept' || action === 'some') {
          // get object with 'scriptId': 'yes|no' values
          const cookies = this.$refs.checkboxes.reduce((cookies, checkbox) => {
            cookies[checkbox.name] =
              checkbox.checked || action === 'accept' ? 'yes' : 'no';

            return cookies;
          }, {});

          // save cookies
          setCookies(cookies);

          // fire events based on cookies and enable tag manager
          CookieConsent.enableTagManager(this.tagManagerId, this.tagManagerSource);
          CookieConsent.fireCookieEvents();
        }

        if(action === 'reject') {

            const cookies = this.$refs.checkboxes.reduce((cookies, checkbox) => {
              cookies[checkbox.name] = 'no'
  
              return cookies
            }, {})
  
            // save cookies
            setCookies(cookies)
        }

        if (this.isModal) this.closeModal()
        
      });
    });
  }

  // set checkbox values based on current cookies
  setCheckboxValues() {
    const cookies = getCookies();

    Object.keys(cookies).forEach((scriptId) => {
      const input = document.getElementById(`checkbox-${scriptId}`);

      if (scriptId === 'must') input.checked = true;
      else if (cookies[scriptId] === 'no') input.checked = false;
      else if (cookies[scriptId] === 'yes')  input.checked = true;
    });
  }

  // loads script tag
  static enableTagManager(tagManagerId, tagManagerSource) {
    CookieConsent.loadScript(tagManagerId, tagManagerSource);
  }

  // fire tag manager events based on cookie-consent data
  static fireCookieEvents() {
    if (!CookieConsent.hasAnyTrueValue()) return;

    const cookies = getCookies();

    Object.keys(cookies).forEach((scriptId) => {
      if (cookies[scriptId] === 'yes') {
        CookieConsent.trackEvent(scriptId, 'yes');
      }
    });

    CookieConsent.trackEvent('acc_consent_ready', 'yes');
  }

  // check if any cookie (script) has the value 'yes'
  static hasAnyTrueValue() {
    const cookies = getCookies();

    return Object.keys(cookies).some((k) => k!== 'must' && cookies[k] === 'yes');
  }

  // appends gtm script tag
  static loadScript(tagManagerId, tagManagerSource) {
    if (!tagManagerId || !tagManagerSource || !this.hasAnyTrueValue()) return;

    switch (tagManagerSource) {
      case 'piwik':
        (function(window, document, dataLayerName, id) {
          window[dataLayerName]=window[dataLayerName]||[],window[dataLayerName].push({start:(new Date).getTime(),event:"stg.start"});var scripts=document.getElementsByTagName('script')[0],tags=document.createElement('script');
          function stgCreateCookie(a,b,c){var d="";if(c){var e=new Date;e.setTime(e.getTime()+24*c*60*60*1e3),d="; expires="+e.toUTCString()}document.cookie=a+"="+b+d+"; path=/"}
          var isStgDebug=(window.location.href.match("stg_debug")||document.cookie.match("stg_debug"))&&!window.location.href.match("stg_disable_debug");stgCreateCookie("stg_debug",isStgDebug?1:"",isStgDebug?14:-1);
          var qP=[];dataLayerName!=="dataLayer"&&qP.push("data_layer_name="+dataLayerName),isStgDebug&&qP.push("stg_debug");var qPString=qP.length>0?("?"+qP.join("&")):"";
          tags.async=!0,tags.src=tagManagerId+qPString,scripts.parentNode.insertBefore(tags,scripts);
          !function(a,n,i){a[n]=a[n]||{};for(var c=0;c<i.length;c++)!function(i){a[n][i]=a[n][i]||{},a[n][i].api=a[n][i].api||function(){var a=[].slice.call(arguments,0);"string"==typeof a[0]&&window[dataLayerName].push({event:n+"."+i+":"+a[0],parameters:[].slice.call(arguments,1)})}}(i[c])}(window,"ppms",["tm","cm"]);
        })(window, document, 'dataLayer', tagManagerId);
        break;
      case 'google':
        (function (w, d, s, l, i) {
          w[l] = w[l] || [];

          w[l].push({
            'gtm.start': new Date().getTime(),
            event: 'gtm.js',
          });

          const f = d.getElementsByTagName(s)[0],
            j = d.createElement(s),
            dl = l != 'dataLayer' ? '&l=' + l : '';

          j.async = true;
          j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
          f.parentNode.insertBefore(j, f);
        })(window, document, 'script', 'dataLayer', tagManagerId);
        break;
    }
  }

  static trackEvent(event, value) {
    const dataLayer = win.dataLayer || [];

    dataLayer.push({
      event,
      value,
    });
  }
}
